import React from 'react';
import { FCC } from 'types';
import cn from 'classnames';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import TextHighlighter from "components/TextHighlighter/TextHighlighter";

import {ServiceItemDTO, ServiceSubItemDTO} from "../types";
import ServiceListItem from "../ServiceListItem/ServiceListItem";

import styles from './ServiceListCategory.module.scss';

export interface ServiceListCategoryProps extends ServiceItemDTO {
    onChange: (item: ServiceSubItemDTO, value: boolean) => void;
    openState: boolean;
    handleOpenState: (state: boolean) => void;
    searchValue?: string;
    selectedServices: ServiceSubItemDTO[];
    isPreselectedQuery?: boolean;
}

const ServiceListCategory: FCC<ServiceListCategoryProps> = ({ searchValue, image, name,items, onChange, handleOpenState, openState, selectedServices, isPreselectedQuery, active }) => {
    const labelAvailablity = isPreselectedQuery ? `${items.filter(item => item.active).length}/${items.length}` : null;

    return (
        // <div className={cn(styles.component, {[styles.disabled]: !active})}>
        <div className={cn(styles.component, {[styles.disabled]: !active})}>
            <Accordion onChange={(e, expanded) => handleOpenState(expanded)}  expanded={openState}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon className={styles.expandIcon} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  // disabling category
                  // can't be overflowed with :before because contrlles will not be available for category accordion and for already selected item in category

                  // className={cn({[styles.disabledSummary]: !active})}
                >
                    <div>
                        {image && (
                            <div className={styles.mainImage}>
                                <img src={image} alt={name}/>
                            </div>
                        )}
                    </div>
                    <div className={styles.categoryNameLine}>
                        <TextHighlighter
                            search={searchValue}
                            text={name}
                        />
                        <span className={styles.labelAvailability}>
                            {labelAvailablity}
                        </span>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    {items.map(item => (
                        <ServiceListItem
                          item={item}
                          onChange={onChange}
                          className={styles.subItem}
                          classNameLabel={styles.subItemLabel}
                          searchValue={searchValue}
                          //@ts-ignore
                          selected={!!selectedServices.find((selectedService: ServiceSubItemDTO) => selectedService.id === item.id)}
                          key={`service-list-item-${item.id}`}
                        />
                    ))}
                </AccordionDetails>
            </Accordion>
        </div>
    )
}

export default ServiceListCategory;
